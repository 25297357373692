//this will have the review
import { createContext } from 'react';
import { Review } from '../types/ApiTypes';

//this will have the company and the settings
export interface ReviewContext {
  review: Review | undefined;
  setReview: React.Dispatch<React.SetStateAction<Review | undefined>>;
}

export const ReviewContext = createContext<ReviewContext>({
  review: undefined,
  setReview: () => {},
});
