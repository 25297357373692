import { CompanyLogoProps } from '../types/PropsTypes';

export function CompanyLogo({ logo, name }: CompanyLogoProps): JSX.Element {
  if (logo) {
    return (
      <div className="logo-wrapper">
        <img className="logo-img" alt="logo-img" src={logo} />
      </div>
    );
  } else {
    return <h1 className="company-name">{name}</h1>;
  }
}
