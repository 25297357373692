import { Modal, Text } from '@mantine/core';
import { VideoErrorProps } from '../../types/PropsTypes';
import { ErrorModalHeader } from './ErrorModalHeader';

export function VideoError({
  errorType,
  ...props
}: VideoErrorProps): JSX.Element {
  if (errorType === 'permissions') {
    return (
      <Modal
        opened={props.opened}
        onClose={props.onClose}
        withCloseButton={true}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <ErrorModalHeader color="red" title="Permissions Denied" />
        <Text>
          In order to record a video, you must allow the browser to record your
          video and audio. Select the small settings button next to the website
          url and enable the camera and microphone permissions, after which you
          will need to refresh the page
        </Text>
      </Modal>
    );
  } else if (errorType === 'notFound') {
    return (
      <Modal opened={props.opened} onClose={props.onClose}>
        <ErrorModalHeader color="red" title="Video not Found" />
        <Text>
          There was no video found when you went to upload your video. Please
          close this window and select start recording again to make sure you
          have a video attached for the review
        </Text>
        <Text size="xs" fs="italic">
          Click X or outside this box to close this message
        </Text>
      </Modal>
    );
  } else if (errorType === 'recording') {
    return (
      <Modal opened={props.opened} onClose={props.onClose}>
        <ErrorModalHeader color="red" title="Recording Error" />
        <Text>
          An error occurred while recording your video, please make sure your
          network connection is working and close this window to record another
          video
        </Text>
        <Text size="xs" fs="italic">
          Click X or outside this box to close this message
        </Text>
      </Modal>
    );
  } else if (errorType === 'upload') {
    return (
      <Modal opened={props.opened} onClose={props.onClose}>
        <ErrorModalHeader color="red" title="Upload Failed" />
      </Modal>
    );
  } else {
    return <></>;
  }
}
