import { Button, Stack, Text } from '@mantine/core';
import { Company, Invite } from '../types/ApiTypes';

export default function NPSInput({
  company,
  invite,
  onSelect,
}: {
  company?: Company;
  invite?: Invite;
  onSelect(n: number): void;
}) {
  const options = [...Array(11).keys()].reverse();

  const headerMessage = (firstName?: string, companyName?: string) =>
    `${firstName}, How likely are you to recommend ${companyName}`;

  return (
    <Stack gap={'md'}>
      <Text size={'md'}>{headerMessage(invite?.firstname, company?.name)}</Text>
      <Button.Group>
        {options.map((n) => {
          return (
            <Button key={n} size={'md'} variant={'default'} onClick={()=>{
                onSelect(n)
            }}>
              {n == 0 ? (
                <Stack gap={0}>
                  {n}
                  <Text size={'xs'} fw={500}>
                    Unlikely
                  </Text>
                </Stack>
              ) : n == 10 ? (
                <Stack gap={0}>
                  {n}
                  <Text size={'xs'} fw={500}>
                    Likely
                  </Text>
                </Stack>
              ) : (
                n
              )}
            </Button>
          );
        })}
      </Button.Group>
    </Stack>
  );
}
