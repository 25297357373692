import { Group, Title } from '@mantine/core';
import {IconAlertHexagon} from '../icons/TablerIcons';
import { ErrorModalHeaderProps } from '../../types/PropsTypes';

export function ErrorModalHeader({
  color,
  title,
}: ErrorModalHeaderProps): JSX.Element {
  return (
    <Group mb={8}>
      <IconAlertHexagon color={color} size={48} />
      <Title>{title}</Title>
    </Group>
  );
}
