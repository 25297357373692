import { ErrorModalProps } from '../types/PropsTypes';
import { Modal400 } from './ErrorContents/Modal400';
import { Modal401 } from './ErrorContents/Modal401';
import {clarityEvent} from "../services/UtilService";

export function ErrorModal({ is400, ...props }: ErrorModalProps): JSX.Element {
  if (is400) {
    if(props.opened){
      clarityEvent("400 Error!")
    }
    return <Modal400 {...props} />;
  } else {
    if(props.opened){
      clarityEvent("401 Unauthorized!")
    }
    return <Modal401 {...props} />;
  }
}
