import {Alert, Button, Center, TextInput, Divider, Stack} from '@mantine/core';
import {useForm} from '@mantine/form';
import {FormEvent, createRef, useContext, useRef, useState} from 'react';
import {
    submitReferralForm,
} from '../../services/SoTellUsApiService';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import {IntlMantinePhoneInput} from '../IntlMantinePhoneInput';
import {ErrorState} from '../../types/PropsTypes';
import {ErrorModal} from '../ErrorModal';
import ReCAPTCHA from 'react-google-recaptcha';
import {CompanyContext} from "../../contexts/CompanyContext";
import {newFacebookIcon} from "../icons/BrandIcons";
import{IconAlertHexagon, IconCircleCheck} from '../icons/TablerIcons';
import RecaptchaText from '../recpatcha/RecaptchaText';
import { SystemContext } from '../../contexts/SystemContext';

type InviteFormData = {
    firstname: string;
    lastname: string;
    email: string;
    optin: boolean;
};

export function ReferralForm({url} : {url: string}): JSX.Element {
    const recaptchaRef = createRef<ReCAPTCHA>();
    const mobileRef = useRef<HTMLInputElement | null>(null);
    const intlRef = useRef<intlTelInput.Plugin | null>(null);
    const [mobileError, setMobileError] = useState<string | null>('');
    const [emailError, setEmailError] = useState<string | null>('');
    const [generalError, setGeneralError] = useState<string | null>('');
    const [infoMessage, setInfoMessage] = useState<string | null>('');
    const [submitting, setSubmitting] = useState(false);

    const{ system } = useContext(SystemContext)
    const {company} = useContext(CompanyContext);

    const [errorState, setErrorState] = useState<ErrorState>({
        is400: false,
        show: false,
    });

    const form = useForm<InviteFormData>({
        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            optin: false,
        },
    });

  const onClickFacebook = (url : string) => {
    window.open(
        encodeURI(
            `https://www.facebook.com/dialog/share?app_id=966242223397117&href=${url}`,
        ),
        '_blank',
        'height=500,width=530,menubar=no,status=no,toolbar=no',
    );
  };

  // function canSubmit(inviteForm: InviteFormData){
  //     if(!inviteForm || !inviteForm.firstname || !inviteForm.lastname || !(inviteForm.email || mobileRef?.current?.value)){
  //         return false
  //     }
  //     return true
  // }

    const handleValidSubmit = async (event:FormEvent<HTMLFormElement> | undefined, inviteForm: InviteFormData) => {
      event?.preventDefault()
      setInfoMessage(null)
      let hasErrors = false;
      const errors = { firstname: '', lastname: '', optin: '' };

      if(!company){
          return
      }

      if (!inviteForm.firstname) {
        errors.firstname = 'First Name is required';
      }
      if (!inviteForm.lastname) {
        errors.lastname = 'Last Name is required';
      }

      if (!mobileRef?.current?.value && !inviteForm.email) {
        setMobileError('Mobile OR email is required');
        setEmailError('Mobile OR email is required');
        hasErrors = true;
      }

      if (errors.firstname || errors.lastname) {
        form.setErrors({ ...errors });
        hasErrors = true;
      }

      if (hasErrors) {
        return;
      }

      let recaptchaToken = null;
      try {
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
          recaptchaToken = await recaptchaRef.current.executeAsync();
        } else {
          recaptchaToken = null;
        }
      } catch {
        recaptchaToken = null;
      }

      setSubmitting(true);

      submitReferralForm(
        inviteForm.firstname,
        inviteForm.lastname,
        inviteForm.email,
        mobileRef?.current?.value ? mobileRef.current?.value : '',
        intlRef?.current?.getSelectedCountryData().iso2
          ? intlRef.current.getSelectedCountryData().iso2
          : company?.defaultCountry
            ? company.defaultCountry
            : 'US',
        recaptchaToken,
        (localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')!.length > 0) ? localStorage.getItem('jwtToken')! : system?.jwtToken ?? "",

      )
        .then((data) => {
            if(data?.status_code == 200 && data?.data?.success){
                setInfoMessage("Thank you for your referral!")
                setEmailError(null)
                setMobileError(null)
                form.reset()
                if(mobileRef?.current) {
                    mobileRef.current.value = ""
                }
            }
            //navigate(`/${inviteId}`);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setErrorState({ ...errorState, show: true });
          } else {
            if (
              error.response.data.error &&
              error.response.data.error instanceof Object
            ) {
              form.setErrors({ ...error.response.data.error });
              if (error.response.data.error.mobile) {
                setMobileError(error.response.data.error.mobile);
              }
              if (error.resonse.data.error.recaptchaToken) {
                setGeneralError(error.response.data.error.recaptchaToken);
              }
            } else {
              setGeneralError(error.response.data.error);
            }
          }
        })
        .finally(() => setSubmitting(false));
    };

    return company ? (
        <div>
            <ErrorModal
                is400={errorState.is400}
                opened={errorState.show}
                title={errorState.title}
                children={errorState.children}
                onClose={() => {
                    setErrorState({
                        ...errorState,
                        show: false,
                    });
                }}
            />
            <p style={{fontWeight: 700, fontSize: 23, padding: 0, margin: 0, marginBottom: 0, textAlign: 'center'}}>Thank you for your review!</p>
            <p style={{fontWeight: 400, fontSize: 17, padding: 0, margin: 0, marginBottom: 20, textAlign: 'center'}}>Please help us out by sharing your review with your Facebook friends.</p>

            <Center>
            {/*<button*/}
            {/*    className="platform-button"*/}
            {/*    type="button"*/}
            {/*    onClick={onClickFacebook}*/}
            {/*>*/}
            {/*  <div className="social-icon">{facebookIcon('35', '35')}</div>*/}
            {/*  <p className="button-label">Share to Facebook</p>*/}
            {/*</button>*/}
                <Button color={'#0866ff'} size={'md'} w={'auto'} onClick={()=>{
                    onClickFacebook(url)
                }}><span style={{paddingRight: 10}}>{(newFacebookIcon('25','25'))}</span> Share to Facebook</Button>
            </Center>

            <Divider label={'OR'} py={'lg'} variant={'dotted'} size={'md'} styles={{label: {fontSize: 20, fontWeight: 800}}}/>

            {generalError && (
                <Alert
                    w="100%"
                    variant="light"
                    color="red"
                    onClose={()=>{
                        setGeneralError(null)
                    }}
                    icon={<IconAlertHexagon/>}
                    withCloseButton
                >
                    {generalError}
                </Alert>
            )}

            {!generalError && infoMessage && (
                <Alert
                w={'100%'} variant={'light'} color={'green'} icon={<IconCircleCheck/>}>
                    {infoMessage}
                </Alert>
            )}

            <Stack gap={0}>
                <p style={{fontSize: 23, textAlign: 'center', fontWeight: 700, margin: 0}}>Have someone in mind?<br/></p>
                <p style={{fontSize: 18, textAlign: 'center', fontWeight: 400, margin: 0}}>Share by phone number and/or email.</p>
            </Stack>

            <form
                data-clarity-unmask={true}
                onSubmit={form.onSubmit((values,e) => {
                   handleValidSubmit(e,values);
                })}
            >
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}
                />
                <div className="invite-forms">
                    <TextInput label="First Name" {...form.getInputProps('firstname')} />
                </div>
                <div className="invite-forms">
                    <TextInput label="Last Name" {...form.getInputProps('lastname')} />
                </div>
                <div className="invite-forms">
                    <IntlMantinePhoneInput
                        initialCountryCode={
                            company?.defaultCountry
                                ? company.defaultCountry
                                : 'US'
                        }
                        mobileRef={mobileRef}
                        intlRef={intlRef}
                        error={mobileError??''}
                    />
                </div>
                <div className="invite-forms">
                    <TextInput error={emailError} label="Email" {...form.getInputProps('email')} mb={10}/>
                </div>
                <div className="invite-submit-btn invite-forms centered-group">
                    <Button style={{padding: '0 35px'}} type="submit" loading={submitting}>
                        Submit
                    </Button>
                </div>
                <RecaptchaText />
            </form>
        </div>
    ) : (
        <h1>Cannot load company!</h1>
    );
}
