export {}

declare const window: any;

export function clarityEvent(eventName : string){
    if(window && window.clarity){
        window.clarity("event", eventName)
    }
}


export function isNPSTestCompany (companyUID:string){
    const dont_redirect_these = ['quantum-nexus-labs', 'mike-test-company'];
    return dont_redirect_these.includes(companyUID);
}

