import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createTheme, MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import './index.css';
import { InviteForm } from './pages/InviteForm';
import { NotFound } from './pages/NotFound';
import { ReviewProcess } from './pages/ReviewProcess';
import SystemProvider from './providers/SystemProvider';

const theme = createTheme({});

const router = createBrowserRouter([
  {
    // Route for the invite form, if there is no id present
    path: '/r/:companyName',
    element: <InviteForm />,
  },
  {
    // Route for the invite form with prefilled data
    path: '/r/:companyName/*',
    element: <InviteForm />,
  },
  {
    // Route for the invite form, if there is no id present
    path: '/r/:companyName/video',
    element: <InviteForm />,
  },
  {
    // Route for the invite form, if there is no id present
    path: '/r/:companyName/text',
    element: <InviteForm />,
  },
  {
    //Route for if they do have an invite id, and potentially a step as well, which could be overrided depending on what the data looks like
    path: '/:inviteId',
    element: <ReviewProcess />,
  },
  {
    //Route for anything else that they type, maybe we just want it as a redirect or something??
    path: '*',
    element: <NotFound />,
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <SystemProvider>
        <RouterProvider router={router} />
      </SystemProvider>
    </MantineProvider>
  </React.StrictMode>,
);
