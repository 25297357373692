import { PlatformButtonProps } from '../types/PropsTypes';
import { stuIcon, recordIcon } from './icons/StuIcons';
import {
  facebookIcon,
  yelpIcon,
  googleIcon,
  angiLogo,
  bbbLogo,
  homeAdvisorLogo,
  houzzLogo,
  zillowLogo,
} from './icons/BrandIcons';

interface AvailableNames {
  [key: string]: JSX.Element;
}

const getIcon = (name: string, textColor='#ffffff'): JSX.Element => {
  const availableNames: AvailableNames = {
    SoTellUs: <div className="social-icon">{stuIcon('35', '35', textColor)}</div>,
    Yelp: <div className="social-icon">{yelpIcon('35', '35', textColor)}</div>,
    Google: <div className="social-icon">{googleIcon('35', '35', textColor)}</div>,
    Facebook: <div className="social-icon">{facebookIcon('35', '35', textColor)}</div>,
    'SoTellUs Video': (
      <div className="social-icon">{recordIcon('45', '38', textColor)}</div>
    ),
    BBB: <div className="social-icon">{bbbLogo('50', '33', textColor)}</div>,
    "Angie's List": (
      <div className={'social-icon'}>{angiLogo('40', '38', textColor)}</div>
    ),
    HomeAdvisor: (
      <div className={'social-icon'}>
        {homeAdvisorLogo('38', '38', textColor)}
      </div>
    ),
    Zillow: (
      <div className={'social-icon'}>{zillowLogo('40', '30', textColor)}</div>
    ),
    Houzz: (
      <div className={'social-icon'}>{houzzLogo('43', '38', textColor)}</div>
    ),
  };

  return availableNames[name] ? (
    availableNames[name]
  ) : (
    <span className="social-icon" />
  );
};

const getName = (name: string): string => {
  if (name === 'SoTellUs') {
    return "I'll Review Here";
  } else if (name === 'SoTellUs Video') {
    return 'Record a Video Review';
  } else {
    return 'Review on ' + name;
  }
};

function getTextColor(backgroundColor: string) {
  // Convert hex to RGB
  let r = parseInt(backgroundColor.slice(1, 3), 16);
  let g = parseInt(backgroundColor.slice(3, 5), 16);
  let b = parseInt(backgroundColor.slice(5, 7), 16);

  // Calculate relative luminance
  function luminance(r: number, g: number, b: number) {
    let a = [r, g, b].map(function (v) {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  // Calculate contrast ratio
  function contrast(l1:number, l2:number) {
    return (l1 + 0.05) / (l2 + 0.05);
  }

  let luminanceBg = luminance(r, g, b);
  let luminanceWhite = luminance(255, 255, 255);
  let luminanceBlack = luminance(0, 0, 0);

  // Calculate contrast ratios
  let contrastWithWhite = contrast(luminanceWhite, luminanceBg);
  let contrastWithBlack = contrast(luminanceBlack, luminanceBg);

  // Check if the background color is very light or very dark
  if (luminanceBg > 0.4) {
    return '#000000'; // Use black text for very light backgrounds
  } else if (luminanceBg < 0.2) {
    return '#FFFFFF'; // Use white text for very dark backgrounds
  } else {
    // Return the text color with higher contrast for intermediate colors
    return contrastWithWhite > contrastWithBlack ? '#FFFFFF' : '#000000';
  }
}

export function PlatformButton({
  writtenButton,
  ...props
}: PlatformButtonProps): JSX.Element {
  let textColor = getTextColor(writtenButton.buttonColor)
  return (
    <button className="platform-button" style={{backgroundColor: writtenButton.buttonColor}} type="button" onClick={props.onClick}>
      {getIcon(writtenButton.name, textColor)}
      <p className="button-label" style={{color: textColor}}>{getName(writtenButton.name)}</p>
    </button>
  );
}
