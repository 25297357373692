import { Button, Modal, ModalProps, Text } from '@mantine/core';
import { ErrorModalHeader } from './ErrorModalHeader';

export function Modal401({ ...props }: ModalProps): JSX.Element {
  const onRefresh = () => {
    location.reload();
  };
  return (
    <Modal
      opened={props.opened}
      onClose={() => {}}
      withCloseButton={false}
      closeOnEscape={false}
      closeOnClickOutside={false}
    >
      <ErrorModalHeader color="orange" title="Session Expired" />
      <Text mt={5}>Please refresh your session to continue</Text>
      <Button mt={5} onClick={onRefresh}>
        Refresh
      </Button>
    </Modal>
  );
}
