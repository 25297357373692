import { createContext } from 'react';
import { Company } from '../types/ApiTypes';

//this will have the company and the settings
export interface CompanyContext {
  company: Company | undefined;
  setCompany: React.Dispatch<React.SetStateAction<Company | undefined>>;
}

export const CompanyContext = createContext<CompanyContext>({
  company: undefined,
  setCompany: () => {},
});
