//this will have the invite
import { createContext } from 'react';
import { Invite } from '../types/ApiTypes';

//this will have the company and the settings
export interface InviteContext {
  invite: Invite | undefined;
  setInvite: React.Dispatch<React.SetStateAction<Invite | undefined>>;
}

export const InviteContext = createContext<InviteContext>({
  invite: undefined,
  setInvite: () => {},
});
