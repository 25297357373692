import { Alert, Button, Checkbox, TextInput, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import {
  getClientInviteFormInfo,
  submitInviteForm,
  submitInviteFormForAnonymous,
} from '../services/SoTellUsApiService';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { BareCompany } from '../types/ApiTypes';
import { CompanyLogo } from '../components/CompanyLogo';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import { IntlMantinePhoneInput } from '../components/IntlMantinePhoneInput';
import { isVideoOnly, isTextOnly } from '../services/ReviewProcessService';
import { ErrorState } from '../types/PropsTypes';
import { ErrorModal } from '../components/ErrorModal';
import ReCAPTCHA from 'react-google-recaptcha';
import { IconAlertHexagon } from '../components/icons/TablerIcons';
import { Loader } from '@mantine/core';
import RecaptchaText from '../components/recpatcha/RecaptchaText'
import { SystemContext } from '../contexts/SystemContext';

type InviteFormData = {
  firstname: string;
  lastname: string;
  email: string;
  optin: boolean;
};

export function InviteForm({
  include_contact_info = true,
  local_company,
  local_invite,
  onSubmit,
}: {
  include_contact_info?: boolean;
  local_company?: any;
  local_invite?: any;
  onSubmit?(first: string, last: string): void;
}): JSX.Element {
  const navigate = useNavigate();
  const { companyName } = useParams();
  const [searchParams] = useSearchParams()
  const recaptchaRef = useRef<ReCAPTCHA|null>(null);
  const mobileRef = useRef<HTMLInputElement | null>(null);
  const intlRef = useRef<intlTelInput.Plugin | null>(null);
  const [mobileError, setMobileError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [pullingClientInfo, setPullingClientInfo] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [bareCompanyInfo, setBareCompanyInfo] = useState<BareCompany | null>(
    null,
  );
  const { system, setSystem } = useContext(SystemContext);

  const [errorState, setErrorState] = useState<ErrorState>({
    is400: false,
    show: false,
  });

  const form = useForm<InviteFormData>({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      optin: false,
    },
  });

  useEffect(()=>{
    let firstname = searchParams.has('firstname') ? searchParams.get('firstname') : '';
    let lastname = searchParams.has('lastname') ? searchParams.get('lastname') : '';
    let mobile1 = searchParams.has('mobile') ? searchParams.get('mobile') : '';
    let email = searchParams.has('email') ? searchParams.get('email') : '';
    form.setValues({firstname: firstname??"", lastname: lastname??"", email: email??"", optin: false})
    if(intlRef!=undefined && mobile1!=null && mobile1!=undefined && mobile1!='') {
      setTimeout(()=>{
        if(mobile1!=null) {
          intlRef.current?.setNumber(mobile1)
        }
      }, 50)
    }
  }, [searchParams, intlRef.current])

  useEffect(()=>{
    if(form.errors && Object.keys(form.errors).length>0 && submitting){
      setSubmitting(false);
    }
  },[form])


  useEffect(() => {
    let companyUnique = companyName;
    if (local_company && !include_contact_info) {
      companyUnique = local_company.uniqueid;
    }
    getClientInviteFormInfo(companyUnique, local_invite?.inviteId, setSystem)
      .then(async(data) => {

        if(data.allowAnonymous){
          await handleAnonymousInvite();
          return data;
        }
        setBareCompanyInfo(data);
      })
      .catch(() => {
        //Nothing here, if it fails it means there is no company and we have nothing to show the enduser
      })
      .then((data) => {
        if(!data?.allowAnonymous){
          setPullingClientInfo(false);  
        }
      }) 
      .finally(() => {/* nothing to happen here */});
  }, []);




  const handleValidSubmit = async (event:FormEvent<HTMLFormElement> | undefined, inviteForm: InviteFormData) => {
    setSubmitting(true);
    event?.preventDefault()
    let hasErrors = false;
    const errors = { firstname: '', lastname: '', optin: '' };

    if (!inviteForm.firstname) {
      errors.firstname = 'First Name is required';
    }
    if (!inviteForm.lastname) {
      errors.lastname = 'Last Name is required';
    }

    if (!inviteForm.optin) {
      errors.optin =
        'You must agree to receive automated review and referral messages';
    }

    if (include_contact_info) {
      if (!mobileRef?.current?.value && !inviteForm.email) {
        setMobileError('Mobile OR Email is required');
        hasErrors = true;
      } else if (mobileError) {
        setMobileError('');
      }
    }

    if (errors.firstname || errors.lastname || errors.optin) {
      form.setErrors({ ...errors });
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    let recaptchaToken = null;

    try {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
        recaptchaToken = await recaptchaRef.current.executeAsync();
      } else {
        recaptchaToken = null;
      }
    } catch(e){
      recaptchaToken = null;
    }


    submitInviteForm(
      inviteForm.firstname,
      inviteForm.lastname,
      inviteForm.email,
      mobileRef?.current?.value ? mobileRef.current.value : '',
      intlRef?.current?.getSelectedCountryData().iso2
        ? intlRef.current.getSelectedCountryData().iso2
        : bareCompanyInfo?.defaultCountry
          ? bareCompanyInfo.defaultCountry
          : 'US',
      inviteForm.optin,
      isVideoOnly(),
      isTextOnly(),
      recaptchaToken,
      local_invite?.inviteId,
      (localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')!.length > 0) ? localStorage.getItem('jwtToken')! : system?.jwtToken ?? ""
    )
      .then((inviteId: string) => {
        if (!include_contact_info && onSubmit) {
          onSubmit(inviteForm.firstname, inviteForm.lastname);
        }
        navigate(`/${inviteId}`);
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          setErrorState((prev)=>({ ...prev, show: true }));
        } else {
          if (
            error.response.data.error &&
            error.response.data.error instanceof Object
          ) {
            form.setErrors({ ...error.response.data.error });
            if (error.response.data.error.mobile) {
              setMobileError(error.response.data.error.mobile);
            }
            if (error.response.data.error.recaptchaToken) {
              setGeneralError(error.response.data.error.recaptchaToken);
            }
          } else {
            setGeneralError(error.response.data.error);
          }
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleAnonymousInvite = async () =>{
    submitInviteFormForAnonymous(
      isVideoOnly(),
      isTextOnly(),
      (localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')!.length > 0) ? localStorage.getItem('jwtToken')! : system?.jwtToken ?? ""
    )
      .then((inviteId: string) => {
        navigate(`/${inviteId}`);
      })
      .catch((error: any) => {
        setGeneralError(error.response.data.error);
      })
      .finally(() => {});
  }

  if (pullingClientInfo) {
    return (
      <div className="centered-stack" style={{minHeight: '100vh'}}>
        <Loader size={50} />
      </div>
    );
  } else if (!bareCompanyInfo?.name) {
    return (
      <div className="centered-stack">
        <h1>That client was not found</h1>
      </div>
    );
  } else {
    return (
      <div className="main-box">
        <ErrorModal
          is400={errorState.is400}
          opened={errorState.show}
          title={errorState.title}
          children={errorState.children}
          onClose={() => {
            setErrorState((prev)=>({ ...prev,
              show: false,
            }));
          }}
        />
        <CompanyLogo name={bareCompanyInfo.name} logo={bareCompanyInfo.logo} />
        <h3>
          Let's start your review for <br /> {bareCompanyInfo.name}
        </h3>
        {generalError && (
          <Alert
            w="100%"
            variant="light"
            color="red"
            icon={<IconAlertHexagon color="red" />}
            withCloseButton
          >
            {generalError}
          </Alert>
        )}
        <form
          data-clarity-unmask={true}
          onSubmit={form.onSubmit((values, e) => {
            handleValidSubmit(e,values);
          })}
        >
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}

          />
          <div className="invite-forms">
            <TextInput
              label="First Name"
              {...form.getInputProps('firstname')}
            />
          </div>

          <div className="invite-forms">
            <TextInput label="Last Name" {...form.getInputProps('lastname')} />
          </div>
          {include_contact_info ? (
            <>
              <div className="invite-forms">
                <IntlMantinePhoneInput
                  initialCountryCode={
                    bareCompanyInfo?.defaultCountry
                      ? bareCompanyInfo.defaultCountry
                      : 'US'
                  }
                  mobileRef={mobileRef}
                  intlRef={intlRef}
                  error={mobileError}
                />
              </div>
              <div className="invite-forms">
                <TextInput
                  label="Email"
                  {...form.getInputProps('email')}
                  mb={10}
                />
              </div>
            </>
          ) : (
            <> </>
          )}
          <div className="invite-forms">
            <Checkbox
              label={
                <Text size="xs" c="gray.8">
                  I agree to receive automated messages from {bareCompanyInfo.name} and SoTellUs. Message frequency may vary. Message &
                  data rates apply. Reply STOP to opt-out.
                </Text>
              }
              {...form.getInputProps('optin', { type: 'checkbox' })}
              mb={10}
            />
          </div>
          <div className="invite-submit-btn invite-forms">
            <Button
              style={{ padding: '0 35px' }}
              type="submit"
              loading={submitting}
              w={'100%'}
            >
              Submit
            </Button>
          </div>
            <RecaptchaText />
        </form>
      </div>
    );
  }
}
