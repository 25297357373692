import { Button, Radio, Rating, Title } from '@mantine/core';
import { RatingSelectProps } from '../types/PropsTypes';
// import { useViewportSize } from '@mantine/hooks';

export function RatingSelect({
  firstName,
  companyName,
  onSelect,
}: RatingSelectProps): JSX.Element {
  // const { width } = useViewportSize();
  const headerMessage = (name: string, company: string) => {
    if (name) {
      return (<span >{`Hey ${name.charAt(0).toUpperCase() + name.slice(1)}!`}<br/><br/>{`How was your experience with`}<br/> {`${company}?`}</span>);
    } 
    return `How was your experience with ${company}?`;
  };

  // const ratingSize = (() =>{
  //   if(width>1000 && width >= 1040){
  //     return 110;
  //   }
  //   return width/5.7
  // })();


  function RadioStar({starValue}:{starValue:number}){
    return(
    <Radio 
      value={starValue} 
      my={50} 
      pos='relative'
      label={
        <div>
          <Button 
            p={0} 
            pos='absolute'
            w={'100%'}
            onClick={()=>onSelect(starValue)}
            style={{backgroundColor:'transparent', zIndex: 2}}>
          </Button>
          <Rating readOnly mt={0} size={30} value={starValue} />
        </div>
      }
        styles={{body:{alignItems:'center'}}} 
    />)
  }



  return (
    <div className="centered-stack">
      <Title className="header-text">{headerMessage(firstName, companyName)}</Title>
      {/*<Rating
        mt={25}
        size={ratingSize}
        onChange={(value: number) => onSelect(value)}
        
      />*/}

        <Radio.Group onChange={(value:string)=>onSelect(Number(value))}>
          <RadioStar starValue={5} />
          <RadioStar starValue={4} />
          <RadioStar starValue={3} />
          <RadioStar starValue={2} />
          <RadioStar starValue={1} />
        </Radio.Group>
    </div>
  );
}
