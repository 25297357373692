import { Input } from '@mantine/core';
import intlTelInput from 'intl-tel-input';
import { useEffect } from 'react';
import { IntlMantinePhoneInputProps } from '../types/PropsTypes';
import './PhoneInput.css';

export function IntlMantinePhoneInput({
  mobileRef,
  intlRef,
  initialCountryCode,
  error,
}: IntlMantinePhoneInputProps): JSX.Element {
  useEffect(() => {
    if (mobileRef.current) {
      intlRef.current = intlTelInput(mobileRef.current, {
        initialCountry: initialCountryCode,
      });
    }
  }, [mobileRef.current]);

  return (
    <Input.Wrapper label="Mobile" error={error}>
      <Input type="phone" ref={mobileRef} w="100%" error={error} />
    </Input.Wrapper>
  );
}
