import { useState } from 'react';
import { SystemContext } from '../contexts/SystemContext';
import { SystemType } from '../types/ApiTypes';

const SystemProvider = ({ children }:any) => {
  const [system, setSystem] = useState<SystemType | undefined>(undefined);

  return (
    <SystemContext.Provider value={{ system, setSystem }}>
      {children}
    </SystemContext.Provider>
  );
};

export default SystemProvider;
