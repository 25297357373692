import { createContext } from 'react';
import { SystemType } from '../types/ApiTypes';

//this will have the company and the settings
export interface SystemContext {
  system: SystemType | undefined;
  setSystem: React.Dispatch<React.SetStateAction<SystemType | undefined>>;
}

export const SystemContext = createContext<SystemContext>({
  system: undefined,
  setSystem: () => {},
});
