import { Button } from '@mantine/core';

export function NotFound(): JSX.Element {
  return (
    <div className="centered-stack">
      <h1 style={{ padding: 0, margin: 0 }}>Page Not Found</h1>
      <p>The page you requested was not found</p>
      <Button
        size={'md'}
        variant={'transparent'}
        onClick={() => {
          window.location.href = 'https://sotellus.com/';
        }}
      >
        Take me to Homepage
      </Button>
    </div>
  );
}
