export const IconChevronLeft = ({
  size = 20,
  color = '#ffffff',
  style = {},
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    strokeWidth="2.5"
    stroke={color}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ verticalAlign: 'middle', ...style }}
  >
    <path d="M15 6l-6 6l6 6" />
  </svg>
);

export const IconRetry = ({  size = 20,
  color = '#ffffff',
  style = {},}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ verticalAlign: 'middle', ...style }}
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
    <path d="M20 4v5h-5" />
  </svg>
);

export const IconCircleCheck = ({ size = 20, color = 'green', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke={color}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ verticalAlign: 'middle', ...style }}
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
    <path d="M9 12l2 2l4 -4" />
  </svg>
);

export const IconVideo = ({ size = 25, color = '#ffffff', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor" // Use "currentColor" for stroke color
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ verticalAlign: 'middle', ...style, color }} // Apply color dynamically
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
    <path d="M3 6m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
  </svg>
);

export const IconVideoOff = ({ size = 25, color = '#ffffff', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor" // Use "currentColor" for stroke color
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ verticalAlign: 'middle', ...style, color }} // Apply color dynamically
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M3 3l18 18" />
    <path d="M15 11v-1l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -.675 .946" />
    <path d="M10 6h3a2 2 0 0 1 2 2v3m0 4v1a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h1" />
  </svg>
);

export const IconDeviceFloppy = ({
  size = 25,
  color = '#ffffff',
  style = {},
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor" // Use "currentColor" for stroke color
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ verticalAlign: 'middle', ...style, color }} // Apply color dynamically
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />
    <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
    <path d="M14 4l0 4l-6 0l0 -4" />
  </svg>
);

export const IconAlertHexagon = ({
  size = 20,
  color = '#ffffff',
  style = {},
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor" // Use "currentColor" for stroke color
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ verticalAlign: 'middle', ...style, color }} // Apply color dynamically
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" />
    <path d="M12 8v4" />
    <path d="M12 16h.01" />
  </svg>
);

export const IconCopy = ({ size = 20, color = '#ffffff', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor" // Use "currentColor" for stroke color
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ verticalAlign: 'middle', ...style, color }} // Apply color dynamically
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
  </svg>
);

export const IconMail = ({ size = 20, color = '#ffffff', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor" // Use "currentColor" for stroke color
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ ...style, color }} // Apply color dynamically
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
    <path d="M3 7l9 6l9 -6" />
  </svg>
);

export const IconMessage2 = ({ size = 20, color = '#ffffff', style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor" // Use "currentColor" for stroke color
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ verticalAlign: 'middle', ...style, color }} // Apply color dynamically
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M8 9h8" />
    <path d="M8 13h6" />
    <path d="M9 18h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-3l-3 3l-3 -3z" />
  </svg>
);
