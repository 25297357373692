export default function RecpatchaText(){
    return(
        <div className="grecaptcha-container">
        <small>
          This site is protected by reCAPTCHA and the Google &nbsp;
          <a href="https://policies.google.com/privacy">
            Privacy Policy
          </a>
          &nbsp;and&nbsp;
          <a href="https://policies.google.com/terms">
            Terms of Service
          </a>
          &nbsp;apply.
        </small>
      </div>
    );
}