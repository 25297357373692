import axios from 'axios';

export function createAxiosInstance(token: string) {
  const instance = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return instance;
}


