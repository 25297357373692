import { useEffect } from 'react';
import { RedirectToUrlProps } from '../../types/PropsTypes';
import { FallbackThankYouPage } from './FallbackThankYouPage';

export function RedirectToUrl({ url }: RedirectToUrlProps): JSX.Element {
  useEffect(() => {
    window.open(url, '_self');
  }, []);

  return <FallbackThankYouPage />;
}
