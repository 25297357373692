import { Modal, ModalProps, Text } from '@mantine/core';
import { ErrorModalHeader } from './ErrorModalHeader';

export function Modal400({ ...props }: ModalProps): JSX.Element {
  return (
    <Modal opened={props.opened} onClose={props.onClose}>
      <ErrorModalHeader color="red" title={props.title} />
      {props.children}
      <Text size="xs" fs="italic">
        Click X or outside this box to close this message
      </Text>
    </Modal>
  );
}
