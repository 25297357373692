import { useViewportSize } from '@mantine/hooks';
import { facebookIcon, xIcon } from '../icons/BrandIcons';
import { ThankYouShareProps } from '../../types/PropsTypes';
import { useContext } from 'react';
import { CompanyContext } from '../../contexts/CompanyContext';
import { IconMail, IconMessage2 } from '../icons/TablerIcons';
import { Title } from '@mantine/core';

export function ThankYouShare({
  url,
  isOffer,
}: ThankYouShareProps): JSX.Element {
  const { width } = useViewportSize();
  const { company } = useContext(CompanyContext);

  const onClickFacebook = () => {
    window.open(
      encodeURI(
        `https://www.facebook.com/dialog/share?app_id=966242223397117&href=${url}`,
      ),
      '_blank',
      'height=500,width=530,menubar=no,status=no,toolbar=no',
    );
  };

  const onClickX = () => {
    window.open(
      encodeURI(`https://www.twitter.com/share?url=${url}`),
      '_blank',
      'height=500,width=530,menubar=no,status=no,toolbar=no',
    );
  };

  const onClickEmail = () => {
    const subject = 'Check Out ' + (isOffer ? 'This Offer' : 'My Review');
    const offerBody = `Hey,\n${company?.name} has a great offer right now, you should check it out before it's too late!\nHere is the link: ${url}`;
    const reviewBody = `Hey,\nI recently left a review for ${company?.name}, you should check it out!\nHere is the link: ${url}`;
    window.open(
      encodeURI(
        `mailto:?subject=${subject}&body=${isOffer ? offerBody : reviewBody}`,
      ),
      '_blank',
      'height=500,width=530,menubar=no,status=no,toolbar=no',
    );
  };

  const onClickMobile = () => {
    const offerBody = `Hey,\n${company?.name} has a great offer right now, you should check it out before it's too late!\nHere is the link: ${url}`;
    const reviewBody = `Hey,\nI recently left a review for ${company?.name}, you should check it out!\nHere is the link: ${url}`;
    window.open(
      encodeURI(`sms:&body=${isOffer ? offerBody : reviewBody}`),
      '_blank',
      'height=500,width=530,menubar=no,status=no,toolbar=no',
    );
  };

  return (
    <div className="centered-stack">
      <Title className='header-text' mb={10}>
        {isOffer
          ? <span>Please Share {company?.name}'s <br/> Referral Offer</span>
          : <span>Please Share Your Review of <br/> {company?.name}</span>}&nbsp;
          <span>with Your Friends</span>
      </Title>
      {/* Facebook option */}
      <button
        className="platform-button"
        type="button"
        onClick={onClickFacebook}
      >
        <div className="social-icon">{facebookIcon('35', '35')}</div>
        <p className="button-label">Share to Facebook</p>
      </button>
      {/* X option */}
      <button className="platform-button" type="button" onClick={onClickX}>
        <div className="social-icon">{xIcon('30', '35')}</div>
        <p className="button-label">Share to X</p>
      </button>
      {/* Email Option */}
      <button className="platform-button" type="button" onClick={onClickEmail}>
        <div className="social-icon">
          <IconMail color='gray' size={35} />
        </div>
        <p className="button-label">Share through Email</p>
      </button>
      {/* Mobile Option */}
      {width < 750 && (
        <button
          className="platform-button"
          type="button"
          onClick={onClickMobile}
        >
          <div className="social-icon">
            <IconMessage2 color='gray' size={35} />
          </div>
          <p className="button-label">Share through SMS</p>
        </button>
      )}
    </div>
  );
}
