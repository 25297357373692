import { Button, Rating } from '@mantine/core';
import { RatingChangeBoxProps } from '../types/PropsTypes';
import { useState } from 'react';

export function RatingChangeBox({
  rating,
  onChange,
}: RatingChangeBoxProps): JSX.Element {
  const [disabled, setDisabled] = useState(true);

  const onUpdate = (newRating: number) => {
    if (newRating !== rating) {
      onChange(newRating);
    }
    setDisabled(true);
  };

  return (
    <div className="centered-group">
      <Rating
        readOnly={disabled}
        size={45}
        value={rating}
        onChange={onUpdate}
      />
      <div className="change-rating-btn">
        <Button
          onClick={() => setDisabled(false)}
          style={{
            visibility: disabled ? 'visible' : 'hidden',
            textWrap: 'wrap',
          }}
          variant={'light'}
          color={'gray.7'}
          ml={'md'}
          radius={'lg'}
          size="sm"
        >
          Change Rating
        </Button>
      </div>
      
    </div>
  );
}
