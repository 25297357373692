import { Button } from "@mantine/core";
import { BackButtonProps } from "../types/PropsTypes";
import {IconChevronLeft }  from "./icons/TablerIcons"

export function BackButton({setStep, rating, minStars, context}: BackButtonProps): JSX.Element {
    const setBackRedirect = () => {
        if (context === "rating-review") {
            rating < minStars ? setStep('rating') : setStep('platform')
        } else {
            setStep('platform');
        }
    } 
    return (
        <Button 
          variant="transparent"
          type="submit" 
          color="gray.7" 
          radius={'lg'}
          mr={'sm'}
          onClick={() => setBackRedirect()}
        >
            <IconChevronLeft size={20} color='gray' />
            <span className="back-button-label">Back</span>
        </Button>
    );
}