import { useEffect, useState } from 'react';
import { PlatformOption } from '../types/ApiTypes';
import { ReviewOptionsProps } from '../types/PropsTypes';
import { PlatformButton } from './PlatformButton';
import { getPlatformOptionReviewType } from '../services/ReviewProcessService';
import { Button } from '@mantine/core';

export function ReviewOptions({
  platformOptions,
  onSelect,
}: ReviewOptionsProps): JSX.Element {
  const [showAll, setShowAll] = useState(false);

  const priorityOptions = platformOptions.filter(
    (platformOption) => platformOption.secondary_priority,
  );
  const nonPriorityOptions = platformOptions.filter(
    (platformOption) => !platformOption.secondary_priority,
  );

  useEffect(()=>{
    // we will just redirect to the only option instead of having a way to select review option
    // if they select 1-3 stars it wont ever hit this component
    if(platformOptions.length === 1){
      onSelect(
        platformOptions[0].DOMid,
        platformOptions[0].url,
        getPlatformOptionReviewType(platformOptions[0].DOMid),
      )
    }
  },[])

  return (
    <div className="centered-stack">
      {priorityOptions.map((writtenButton: PlatformOption) => (
        <PlatformButton
          key={writtenButton.name}
          writtenButton={writtenButton}
          onClick={() =>
            onSelect(
              writtenButton.DOMid,
              writtenButton.url,
              getPlatformOptionReviewType(writtenButton.DOMid),
            )
          }
        />
      ))}
      {nonPriorityOptions.length > 0 ? (
        showAll ? (
          nonPriorityOptions.map((writtenButton: PlatformOption) => (
            <PlatformButton
              key={writtenButton.name}
              writtenButton={writtenButton}
              onClick={() =>
                onSelect(
                  writtenButton.DOMid,
                  writtenButton.url,
                  getPlatformOptionReviewType(writtenButton.DOMid),
                )
              }
            />
          ))
        ) : (
          <Button
            radius={'lg'}
            my={'lg'}
            color="gray"
            onClick={() => setShowAll(true)}
          >
            More Options
          </Button>
        )
      ) : null}
    </div>
  );
}
